<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <template v-slot:activator="{ on }">
      <v-chip class="ma-1" color="blue lighten-5" text-color="black" v-on="on">
        <v-avatar left class="blue darken-4" style="color: white !important">
          <v-icon small>{{ btnIcon }}</v-icon>
        </v-avatar>
        <span>{{ btnText }}</span>
      </v-chip>
    </template>
    <v-card :loading="loading">
      <v-card-title
        ><span v-if="isNew">Criar trabalhador</span
        ><span v-if="!isNew">Alterar trabalhador</span></v-card-title
      >
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field
            v-model="s.Name"
            :rules="nameRules"
            label="Name"
            required
          />
          <v-text-field
            v-model="s.Surname"
            :rules="nameRules"
            label="Surname"
            required
          />
          <v-text-field
            v-model="s.Email"
            :rules="emailRules"
            label="Email"
            required
          />
          <v-text-field
            v-model="s.Phone"
            :rules="phoneRules"
            label="Telefone"
            required
          />
          <v-autocomplete
            prepend-icon="mdi-toggle-switch-off-outline"
            v-model="s.IDStaffType"
            :items="roles"
            item-text="Description"
            item-value="ID"
            label="Perfil"
            placeholder="Select staff role"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="!valid"
          v-if="isNew"
          @click="create()"
          >Criar</v-btn
        >
        <v-btn
          color="primary"
          text
          :disabled="!valid"
          v-if="!isNew"
          @click="update()"
          >Alterar</v-btn
        >
        <v-btn text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import httpClient from "../../api/httpClient";
import { bus } from "../../main";

export default {
  props: {
    staffAction: String,
    staffDetails: {},
  },
  data: () => ({
    s: {},
    roles: [],
    isNew: true,
    dialog: false,
    loading: false,
    valid: false,
    nameRules: [(v) => !!v || "O nome é obrigatório"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail tem que ser válido",
    ],
    phoneRules: [
      (v) => !!v || "Phone is required",
      (v) => /\+?[0-9]+/.test(v) || "O telefone tem que ser válido",
    ],
  }),
  computed: {
    btnIcon: function () {
      if (this.staffAction === "update") {
        return "mdi-pencil";
      } else {
        return "mdi-plus";
      }
    },
    btnText: function () {
      if (this.staffAction === "update") {
        return "Alterar";
      } else {
        return "Criar";
      }
    },
  },
  watch: {
    dialog: function (val) {
      if (val) {
        if (this.staffAction === "update") {
          this.isNew = false;
          this.s = this.staffDetails;
        }
        this.getRoles();
      }
    },
  },
  methods: {
    getRoles: function () {
      this.loading = true;
      httpClient
        .get("/mix/api/v1/staffs/roles")
        //.get('http://localhost:13007/api/v1/staffs/roles')
        .then((response) => {
          this.loading = false;
          this.roles = response.data;
        })
        .catch(() => {
          this.loading = false;
          bus.$emit("snackBarMsg", {
            error: true,
            msg: "Erro ao carregar perfis do trabalhdores",
          });
        });
    },
    create: function () {
      this.loading = true;
      httpClient
        .post("/mix/api/v1/staffs", this.s)
        //.post('http://localhost:13007/api/v1/staffs', this.s)
        .then(() => {
          this.loading = false;
          this.dialog = false;
          this.s = {};
          bus.$emit("snackBarMsg", {
            error: false,
            msg: "Trabalhor criado com sucesso",
          });
          bus.$emit("refreshStaffList", null);
        })
        .catch(() => {
          this.loading = false;
          bus.$emit("snackBarMsg", {
            error: true,
            msg: "Erro ao criar trabalhador",
          });
        });
    },
    update: function () {
      httpClient
        //.put('/mix/api/v1/staffs/' + this.s.ID, this.s)
        .put("http://localhost:13007/api/v1/staffs/" + this.s.ID, this.s)
        .then(() => {
          this.loading = false;
          this.dialog = false;
          bus.$emit("snackBarMsg", {
            error: false,
            msg: "Trabalhador atualizado com sucesso",
          });
          bus.$emit("refreshStaffView", null);
        })
        .catch(() => {
          this.loading = false;
          bus.$emit("snackBarMsg", {
            error: true,
            msg: "Erro ao atualizar trabalhador",
          });
        });
    },
  },
};
</script>
