<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    permanent
    clipped
    mini-variant-width
    :width="width"
  >
    <v-list dense>
      <v-list-item @click="expand()">
        <v-list-item-icon>
          <v-icon>{{ expandIcon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ app | capitalize }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="'/chatbot'">
        <v-list-item-icon>
          <v-badge
            overlap
            :content="notificationNumber"
            :value="notificationNumber"
            color="green"
          >
            <v-icon>mdi-robot-happy</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>SmartBot</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.link"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider v-if="isMaster"></v-divider>

    <v-list dense v-if="isMaster">
      <v-list-item
        v-for="item in itemsAdmin"
        :key="item.title"
        link
        :to="item.link"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item link :to="'/user'">
        <v-list-item-icon>
          <v-badge
            overlap
            :content="notificationNumber"
            :value="notificationNumber"
            color="green"
          >
            <v-icon>mdi-account</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ this.$store.state.userName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logout()">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="`/help`">
        <v-list-item-icon>
          <v-icon>mdi-help</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { bus } from '../../main'

export default {
  data() {
    return {
      notificationNumber: 0,
      expandIcon: 'mdi-chevron-right',
      expanded: false,
      isAdmin: false,
      isMaster: false,
      app: "nmSmart",
      db: null,
      drawer: null,
      items: [],
      itemsAdmin: [],
      width: "55px",
    }
  },
  mounted: function () {
    bus.$on('sideBarLogout', () => {
      this.logout()
    })
    /*window.setInterval(() => {
      this.getNotifications()
    }, 60000)*/
  },
  created () {
    this.checkUser()
    bus.$on('loggedIn', () => { 
      this.checkUser()
      bus.$emit('menuMove', this.width) 
    })
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    getNotifications: function () {
      //console.log("NOTIFICATIONS COMMING - " + new Date())
      //this.notificationNumber++
    },
    expand: function () {
      this.expanded = !this.expanded
      if(this.expanded) {
        this.width = "210px"
        this.expandIcon = 'mdi-chevron-left'

        bus.$emit('menuMove', this.width)
      } else {
        this.width = "55px"
        this.expandIcon = 'mdi-chevron-right'

        bus.$emit('menuMove', this.width)
      }
    },
    checkUser: function () {
      if(this.$store.state.token !== null) {
        //this.app = this.$store.state.appID
        this.db = this.$store.state.database
        this.profile = this.$store.state.profile

        if(this.profile === "User") {
          this.items = [
            { title: 'Home', link: '/daily', icon: 'mdi-home' },
            { title: 'Calendar', link: '/calendar', icon: 'mdi-calendar-range' },
          ]
        } else if(this.profile === "Instructor") {
          this.items = [
            { title: 'Home', link: '/daily', icon: 'mdi-home' },
            { title: 'Calendar', link: '/calendar', icon: 'mdi-calendar-range' },
            { title: 'Lessons', link: '/lesson', icon: 'mdi-calendar-account' },
          ]
        } else if(this.profile === "Manager") {
          this.items = [
            { title: 'Home', link: '/daily', icon: 'mdi-home' },
            { title: 'Calendar', link: '/calendar', icon: 'mdi-calendar-range' },
            { title: 'Lessons', link: '/lesson', icon: 'mdi-calendar-account' },
            { title: 'Students', link: '/student', icon: 'mdi-account-group' },
            { title: 'Settings', link: '/settings', icon: 'mdi-cog-outline' },
          ]
        } else if(this.profile === "Administrator") {
          this.items = [
            { title: 'Home', link: '/daily', icon: 'mdi-home' },
            { title: 'Calendar', link: '/calendar', icon: 'mdi-calendar-range' },
            { title: 'Lessons', link: '/lesson', icon: 'mdi-calendar-account' },
            { title: 'Students', link: '/student', icon: 'mdi-account-group' },
            { title: 'Staff', link: '/staff', icon: 'mdi-briefcase-account' },
            //{ title: 'Room', link: '/room', icon: 'mdi-home-city-outline' },
            { title: 'Dashboard', link: '/dashboard', icon: 'mdi-view-dashboard' },
            { title: 'Settings', link: '/settings', icon: 'mdi-cog-outline' },
          ]
        } else {
          // Other profiles, like students and random new users
        }
        //console.log(this.$store.state.isMaster)
        if(this.$store.state.isMaster) {
          this.isMaster = true
          this.itemsAdmin.push({ title: 'Administration', link: '/admin', icon: 'mdi-brain' })
          this.itemsAdmin.push({ title: 'Works', link: '/works', icon: 'mdi-home-modern' })
          this.itemsAdmin.push({ title: 'Equipments', link: '/equipment', icon: 'mdi-nas' })
          this.itemsAdmin.push({ title: 'Transports', link: '/transport', icon: 'mdi-train-car' })

          /*this.itemsAdmin.push({ title: 'Rooms', link: '/rooms', icon: 'mdi-home-city-outline' })*/
        }
      }
    },
    logout: function () {
      console.log("sideBar Logout")
      //localStorage.clear()
      this.$store.commit('setToken', null)
      this.$store.commit('setProfile', null)
      this.$store.commit('setDatabase', null)
      this.$store.commit('setIsAdmin', null)
      this.$store.commit('setIsMaster', null)
      this.showMenu = false
      this.app = "nmSmart"
      this.$router.push('/')

      bus.$emit('logout', '')
    }
  }
}
</script> 
