<template>
  <v-card class="my-0" :loading="loading">
    <v-card-title>
      <v-icon left>mdi-account-group</v-icon> Customers <StudentDialogCreate :buttonLable="'Create'" />
      <AdvancedFilter v-if="showAdvancedFilter" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-search"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="rows"
      :search="search"
      :items-per-page="10"
      :server-items-length="totalRows"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: rowsPerPageItems,
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right'
      }"
    >
      <template v-slot:item.options="{ item }">
        <router-link v-bind:to="'/student/' + item.ID">
          <v-chip
            color="blue lighten-5"
            text-color="black"
          >
            <v-avatar
              left
              class="blue darken-4"
              style="color: white !important"
            >
              <v-icon small>mdi-forward</v-icon>
            </v-avatar>
            View
          </v-chip>
        </router-link>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import httpClient from '../../api/httpClient'
import { bus } from '../../main'
import StudentDialogCreate from './StudentDialogCreate.vue'
import AdvancedFilter from './AdvancedFilter.vue'

export default {
  data () {
    return {
      showAdvancedFilter: true,
      loading: false,
      columns: [
        { text: 'Name', align: 'left', value: 'Name' },
        { text: 'Email', value: 'Email' },
        { text: 'Phone', value: 'Phone' },
        { text: 'Country', value: 'Country' },
        //{ text: 'Supplier', value: 'Supplier' },
        //{ text: 'Board', value: 'Board' },
        //{ text: 'Wetsuit', value: 'Wetsuit' },
        { text: 'Options', align: 'left', sortable: false, value: 'options' }
      ],
      rows: [],
      search: this.$store.state.filters.student.list,
      rowsPerPageItems: [5, 10, 25, 50],
      packagesLoaded: false,
      servicesLoaded: false,
      totalRows: 0,
      options: {}
    }
  },
  created () {
    bus.$on('refreshStudentList', () => { this.getCustomers() })
    bus.$on('filterPackages', (toLoad) => { this.getCustomersWithPackages(toLoad) })
    bus.$on('filterServices', (toLoad) => { this.getCustomersWithServices(toLoad) })
    /* to change in near future */
    bus.$on('customersEmailRequested', () => {
      var toReturn = 'Name, Email\n'
      this.rows.forEach(element => {
        toReturn += element.Name + ', ' + element.Email + '\n'
      })
      bus.$emit('exportedStudents', toReturn)
    })
  },
  beforeDestroy () {
    bus.$off('refreshStudentList', null)
    bus.$off('filterPackages', null)
    bus.$off('filterServices', null)
  },
  watch: {
    'search' () {
      this.$store.commit('filtersStudentList', this.search)
    },
    options: {
      handler () {
        this.getCustomers()
      },
      deep: true,
    },
  },
  methods: {
    getCustomers: function () {
      this.loading = true
      httpClient
        .get('/customer/api/v1/students?'+
        //.get('http://localhost:13007/api/v1/students?'+
          'pageNumber='+this.options.page+'&pageSize='+this.options.itemsPerPage+
          '&sortBy='+this.options.sortBy+'&sortDesc='+this.options.sortDesc)
        .then((r) => {
          this.loading = false
          this.rows = r.data.customers
          this.totalRows = r.data.totalRows
        })
        .catch(() => {
          this.loading = false
          bus.$emit('snackBarMsg', {error: true, msg: 'Error getting students'})
        })
    },
    getCustomersWithPackages: function (toLoad) {
      if (toLoad) {
        this.loading = true
        httpClient
          .get('/customer/api/v1/students/available/packages')
          //.get('http://localhost:13001/api/v1/students/available/packages')
          .then((r) => {
            this.loading = false
            if (this.servicesLoaded) {
              this.rows = [ ...this.rows, ...r.data ]
            } else {
              this.rows = r.data
            }
            this.packagesLoaded = true
          })
          .catch(() => {
            this.loading = false
            bus.$emit('snackBarMsg', {error: true, msg: 'Error getting students with packages'})
          })
      } else {
        this.packagesLoaded = false
        if (this.servicesLoaded) {
          this.getCustomersWithServices(true)
        } else {
          this.getCustomers()
        }
      }
    },
    getCustomersWithServices: function (toLoad) {
      if (toLoad) {
        this.loading = true
        httpClient
          .get('/customer/api/v1/students/available/services')
          //.get('http://localhost:13001/api/v1/students/available/services')
          .then((r) => {
            this.loading = false
            if (this.packagesLoaded) {
              this.rows = [ ...this.rows, ...r.data ]
            } else {
              this.rows = r.data
            }
            this.servicesLoaded = true
          })
          .catch(() => { 
            this.loading = false
            bus.$emit('snackBarMsg', {error: true, msg: 'Error getting students with services'})
          })
      } else {
        this.servicesLoaded = false
        if (this.packagesLoaded) {
          this.getCustomersWithPackages(true)
        } else {
          this.getCustomers()
        }
      }
    },
  },
  components: {
    StudentDialogCreate,
    AdvancedFilter
  }
}
</script>
